.wrapper-userpassicon{
    @media only screen and (max-width: 768px) {
        display: none;    
    }
}

.savepass-container{
    display: flex; 
    justify-content: space-between;
    flex-direction: row; 
    align-items: center;
    padding-right: 4rem;
    @media only screen and (max-width: 768px) {
        padding: 0;
        justify-content: center;
        align-content: center;
        padding: 0 30px;
    }
    &__step{
        margin-top: 8rem; 
        margin-bottom: 17rem;
        @media only screen and (max-width: 768px) {
            margin-top: 2rem; 
            margin-top: 4rem;
        }
        @media only screen and (min-width: 769px) {
            flex: 0 1 50%;
            &__content{
                margin-left: 20px;
            }
        }
    }
    
    input{
        box-sizing: border-box;
        color: #757575;
        width: 100%;
        padding: 15px;
        border-radius: 4px; 
        border: 1px solid #ccc;
        &.error{
            background-color: #f8d7da91;
            border-color: #ff0018b0;
        }
    }

    .input-box-error{
        color: #721c24; 
        font-size: 14px; 
        background-color: #f8d7da;  
        border: 1px solid transparent;
        border-radius: 4px; 
        border-color: #f5c6cb;
        padding: 12px 20px;
    }
}

.success-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 257px);
    .btn-signin{
        background: #fff;
        border: 1px solid rgb(38, 166, 154);
        color: rgb(38, 166, 154);
    }
}

.btn-save-password{
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}