
body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 0;
}

.btn-square{
  background: #fff !important;
  border-radius: 4px !important;
  color: #245E5E !important;
  border: 0;
  padding: 10px 40px !important;
  span{
    font-weight: 450 !important;
  }
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

.content{
  z-index: 1;
}

.page{
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  background-color: transparent;
}

.background-white{
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
}

.square-background{
  height: 30rem;
  width: 72%;
  background-color: #247B7B;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.btn {
  span {
    font-weight: normal;
    text-transform: initial;
  }
  &.default{
    background-color: #26a69a;
    color: #fff;
    border: none;
    &:hover{
      background-color: #2bbbad;
    }
  }
}